<template>
  <div>
    <h3>Report Filters</h3>
    <v-divider />
    <!-- Filter will be here. -->
  </div>
</template>

<script>
export default {
  name: "TransactionReportWrapper"
}
</script>