<template>
  <div>
    <transaction-report-wrapper />
  </div>
</template>

<script>
import TransactionReportWrapper from "@/components/reporting/transaction/TransactionReportWrapper"

export default {
  name: "TransactionReportView",
  components: {
    TransactionReportWrapper,
  }
}
</script>